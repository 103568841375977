import crel from 'crel'
import settings from "../../../../src/es6/settings";


export const hubspot = () => {
    console.log('Hubspot: Running hubspot.js')
    const consent = window.Didomi.getUserConsentStatusForVendor('c:hubspot')
    if(!consent) return

    const script = settings.site_type != 'production' ? 22825699 : 25280231
    const srv = settings.site_type != 'production' ? 'js': 'js-eu1'
    crel(document.head, crel('script', {id: 'hs-script-loader', src: `//${srv}.hs-scripts.com/${script}.js`}))
}