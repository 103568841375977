export const smartoctoTentacles = () => {
    console.debug('smartocto: smartoctoTentacles.js');

    const apiToken = '427axqesgn4vuozd3hkevxwcj0em2jb7'

    const scriptUrl = '//tentacles.smartocto.com/ten/tentacle.js'
    const loaded = document.querySelector(`script[src$="${scriptUrl}"]`)
    const consent = window?.Didomi?.getUserConsentStatusForVendor('c:smartocto-delo')

    if (consent && !loaded) {
        window["tentacles"] = {apiToken};
        (function () {
            var d = document,
                h = d.getElementsByTagName('head')[0],
                s = d.createElement('script');
            s.type = 'text/javascript'
            s.async = true;
            s.src = document.location.protocol + scriptUrl;
            h.appendChild(s);
        }());
    }

    if(consent) window.postMessage('activateTentacles')
}