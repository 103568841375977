const fakedata = {
    "id": 129,
    "title": "Anketa GEN-I Sonce",
    "type": "quiz",
    "live": true,
    "first_published_at": null,
    "name": "",
    "description": "",
    "questions": [
        {
            "id": "0",
            "question": "Kaj vam je najbolj pomembno pri izbiri podjetja za postavitev sončne elektrarne?",
            "image": "/media/original_images/se/kviz/129/Subvencije-Borzen_1600x1200px_72dpi_10.jpg",
            "image_credits": "",
            "state": 1,
            "answers": [
                {
                    "id": "0",
                    "answer": "Cena storitve",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Kako hitro bo sončna elektrarna postavljena",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Strokovnost in zanesljivost",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Kakovost komponent sončne elektrarne",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                }
            ]
        },
        {
            "id": "0",
            "question": "Katera je po vašem mnenju najpomembnejša lastnost tehnične ekipe?",
            "image": "/media/original_images/se/kviz/129/Subvencije-Borzen_1600x1200px_72dpi_11.jpg",
            "image_credits": "",
            "state": 1,
            "answers": [
                {
                    "id": "0",
                    "answer": "Odzivnost",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Prijaznost",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Strokovnost",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Natančnost",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Urejenost",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                }
            ]
        },
        {
            "id": "0",
            "question": "Kdo ali kaj vpliva na izbiro podjetja za postavitev sončne elektrarne?",
            "image": "/media/original_images/se/kviz/129/Subvencije-Borzen_1600x1200px_72dpi_12.jpg",
            "image_credits": "",
            "state": 1,
            "answers": [
                {
                    "id": "0",
                    "answer": "Nasvet prijatelja ali družine",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Članki v medijih",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Družbena omrežja",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Oglasi",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                }
            ]
        },
        {
            "id": "0",
            "question": "Kaj menite o zelenem prehodu?",
            "image": "/media/original_images/se/kviz/129/Subvencije_Borzen_1920x1080px_72dpi_1.jpg",
            "image_credits": "",
            "state": 1,
            "answers": [
                {
                    "id": "0",
                    "answer": "Ključnega pomena za našo prihodnost.",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "O tem ne razmišljam.",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Ne zdi se mi pomemben.",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "O tem se premalo govori. ",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                }
            ]
        },
        {
            "id": "0",
            "question": "Zakaj se odločate za sončno elektrarno?",
            "image": "/media/original_images/se/kviz/129/Subvencije-Borzen_1600x1200px_72dpi_13.jpg",
            "image_credits": "",
            "state": 1,
            "answers": [
                {
                    "id": "0",
                    "answer": "Da znižam račune za elektriko. ",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Ker želim poskrbeti za lepši planet in uporabljati čistejšo energijo.",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Da podražitve elektrike ne bodo vplivale name. ",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Zdi se mi pametna finančna naložba.",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                },
                {
                    "id": "0",
                    "answer": "Sončne elektrarne ne želim postaviti.",
                    "points_up": "0",
                    "points_down": "0",
                    "correct": 0,
                    "state": 1
                }
            ]
        }
    ],
    "result_msgs": "{'0': '', '1_10': '', '11_20': '', '21_30': '', '31_40': '', '41_50': '', '51_60': '', '61_70': '', '71_80': '', '81_90': '', '91_99': '', '100': ''}",
    "image": "/media/original_images/se/kviz/129/Subvencije_Borzen_1920x1080px_3-1.jpg",
    "cat_id": 4,
    "se_id": 75,
    "end_text": "",
    "terms_of_use_url": "",
    "form": []
}

export default fakedata