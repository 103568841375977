export const matomo = () => {
    console.debug('matomo: matomo.js');
    const consent = window?.Didomi?.getUserConsentStatusForVendor('c:matomo-delo')
    if(!consent) return

    const src = 'https://services.delo.si/piwik/js/container_nZo6wj4L.js'
    window._mtm = '_mtm' in window ? window._mtm : []
    window._mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    function removeMatomo() {
        setTimeout(()=>{ document.querySelector(`[src="${src}"]`).remove() }, 500)
    }
    function matomo() {
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.async=true; g.src=src; g.classList.add("matomoScript"); g.onload=removeMatomo;s.parentNode.insertBefore(g,s);
    };
    window.addEventListener("customMatomoClick", matomo);
    matomo()
}