import React, {useState, useEffect} from 'react'
import axios from "axios";
import Poll from "./poll";

function Result({ app_id, section, questions, css, cat_id}) {
    const [polls, setPolls] = useState([])

    const pollUrl = "https://uporabnik.delo.si/services/promo/poll/"; //"https://local-dur.delo.si/services/promo/poll/"

    const fetchResult = async () => {
        const headers = {'x-requested-with': 'INCREDIBLYCOMPLICATEDTOKEN'};
        const response = await axios.get(pollUrl + app_id + '/' + section + '/', {headers});

        setPolls(
            response.data.results.map((obj, index) => ({
                name: questions[section].answers[index].answer, obj, css, section, app_id, cat_id
            }))
        )
    }

    const initComponent = () => {
        fetchResult().catch(console.error);
    }

    const htmlDecode = (input) => {
        var e = document.createElement("div");
        e.innerHTML = input;
        return e.childNodes[0].nodeValue;
    };
    
    useEffect(() => {
        initComponent();
    }, [section, questions]);

    if (questions.length > 0 && polls.length > 0) {
        return (
            <div>
                <div className="result-question"
                     dangerouslySetInnerHTML={{ __html: htmlDecode(questions[section].question) }}>
                </div>
                <div className="result-title"></div>
                {polls.map((props, index) => <Poll {...props} key={index} order={index} />)}
            </div>

            /*
            <div>
                <h2 dangerouslySetInnerHTML={{ __html: htmlDecode(questions[section].question) }}></h2>
                <div className="answersContainer">
                    <div className="answers">
                        {polls.map((props, index) => <Poll {...props} key={index} order={index} />)}
                    </div>
                </div>
            </div>
             */
        )
    }else{
        return <div>Ni podatkov</div>;
    }

}

export default Result;
