import settings from './settings'
import {wait} from './util'

async function runGoogle () {
    const allowed = window.Didomi.getUserConsentStatusForPurpose('third')
    console.log(`Google: running google analytics (allowed: ${allowed})`)
    if (!allowed) return

    (function (i, s, o, g, r, a, m) {
        i['GoogleAnalyticsObject'] = r;
        i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
        a = s.createElement(o),
            m = s.getElementsByTagName(o)[0];
        a.async = 1;
        a.src = g;
        m.parentNode.insertBefore(a, m)
    })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

    ga('create', settings.googleAnalytics, 'auto');
    ga('send', 'pageview');

    // From now on, we need "oglasevalski cookie as well"
    // if(!settings.cookies.includes('oglasevalski')) return

    function gtag(){dataLayer.push(arguments);}

    // Google Tag Manager
    if(settings.googleTagManager) {
            (function (w, d, s, l, i) {
                w[l] = w[l] || [];
                w[l].push({
                    'gtm.start':
                        new Date().getTime(), event: 'gtm.js'
                });
                var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
                j.async = true;
                j.src =
                    'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
                f.parentNode.insertBefore(j, f);
            })(window, document, 'script', 'dataLayer', settings.googleTagManager);
    }

    // Global site tag (GA4) (different URL)
    if(settings.globalSiteTag) {
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtag/js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer', settings.globalSiteTag);
        gtag('js', new Date());
        gtag('config', settings.globalSiteTag);
    }
}


window.addEventListener('didomiLoaded', function () {
    runGoogle()
})

export {
    runGoogle
}