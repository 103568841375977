export const smartoctoAin = async () => {
    console.debug('smartocto: smartoctoAin.js');
    const consent = window?.Didomi?.getUserConsentStatusForVendor('c:smartocto-delo')
    if(!consent && !window._ain) return

    const fRes = await fetch('/accounts/profile/?session=true')
    const jsonRes = await fRes.json()
    if(jsonRes.authenticated) window._ain.reader_type = 'registered'
    if(jsonRes.permissions.access_web) window._ain.reader_type = 'subscribed'

    ;(function () {
        var d = document,
            h = d.getElementsByTagName('head')[0],
            s = d.createElement('script');
        s.type = 'text/javascript'
        s.async = false;
        s.src = (('https:' == d.location.protocol)
            ? 'https://d7d3cf2e81d293050033-3dfc0615b0fd7b49143049256703bfce.ssl.cf1.rackcdn.com'
            : 'http://t.contentinsights.com') + '/stf.js';
        h.appendChild(s);
    }());
}
