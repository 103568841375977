import crel from 'crel'

// window.addEventListener('load', function() {
//     if(checkCookie && !checkCookie("oglasevalski")) return // If cookies are not allowed
//
//     crel(document.head, crel('script', {src: `//d.get-native.eu/cds/delivery/init`}))
// })

export const alliance = () => {
    console.log('alliance: alliance.js')
    const consent = window.Didomi.getUserConsentStatusForPurpose('third')
    if(!consent) return
    crel(document.head, crel('script', {src: `//d.get-native.eu/cds/delivery/init`}))
}