import { sequentialLoad, id } from '../../../../src/es6/util'

// window.addEventListener('load', function() {
//     if (checkCookie("analiticni") != true) return
//     const scripts = [
//         `https://static.delo.si/firebase.min.js?rev=${push_notifications.rev}`
//     ]
//     sequentialLoad(scripts)
// })

export const pushNotifications = () => {
    console.log('push notifications: push_notifications.js')
    const consent = window.Didomi.getUserConsentStatusForPurpose('third')
    if(!consent) return

    const scripts = [
        `https://static.delo.si/firebase.min.js?rev=${id()}`
    ]
    sequentialLoad(scripts)
}