import React, {useState, useEffect} from 'react'
import axios from "axios";
import InputElement from "./input_element";

function UserData({app_id, title, result, form, css, ip, section, score, total, endText, terms}) {
    const [formError, setFormError] = useState("");
    const [finish, setFinish] = useState(false);
    const [inputFields, setInputFields] = useState(form);
    const [submited, SetSubmited] = useState(false);
    const pollUrl = "https://uporabnik.delo.si/services/promo/poll/"; //"https://local-dur.delo.si/services/promo/poll/"

    const validateEmail = (email) => {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const fetchLocalStorage = () => {
        if(JSON.parse(localStorage.getItem('poll_form_'+app_id))) {
            SetSubmited(true);
        }
    }

    const initComponent = () => {
        fetchLocalStorage();
    }

    useEffect(() => {
        initComponent()
    }, []);

    const pushData = async () => {
        let data = [];
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].type === "checkbox") {
                data[i] = {
                    [htmlDecode(inputFields[i].label)]: inputFields[i].checked ? "1" : "0",
                };
            } else {
                data[i] = {
                    [htmlDecode(inputFields[i].label)]: htmlDecode(inputFields[i][inputFields[i].name]),
                };
            }
        }

        const headers = {'x-requested-with': 'INCREDIBLYCOMPLICATEDTOKEN'};
        await axios.post(pollUrl + app_id + '/' + section + '/', {
            data: data,
            ip: ip,
            result: result,
            app_id: app_id,
            title: title,
            created: new Date()
                .toISOString()
                .slice(0, 19)
                .replace(/-/g, "/")
                .replace("T", " "),
        }, {headers});
        let obj = {poll: app_id, user:'form', timestamp: new Date().getTime()}
        localStorage.setItem('poll_form_'+app_id, JSON.stringify(obj))
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        let post = true;
        for (var i = 0; i < inputFields.length; i++) {
            if (inputFields[i].name === "email") {
                if (!validateEmail(inputFields[i].email)) {
                    post = false;
                    setFormError("Prosimo vnesite pravilen e-poštni naslov.");
                }
            }
        }

        if (post) {
            pushData();
            setFinish(true);
        }
    };

    const htmlDecode = (input) => {
        let doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    const handleFormChange = (index, event) => {
        let data = [...inputFields];
        if (event.target.type === "checkbox") {
            data[index]["checked"] = event.target.checked;
        } else {
            data[index][event.target.name] = event.target.value;
        }
        setInputFields(data);
    };

    let f = '';
    if(form && form.length>0 && !submited){
        f = <form onSubmit={handleSubmit}>
                    <InputElement inputFields={inputFields} handleFormChange={handleFormChange}/>
                    <div className="row">
                        <div className="quiz-right col s12">
                            <button className={`waves-effect waves-light btn quiz-${css.split(';')[1]}`}>POŠLJI</button>
                        </div>
                    </div>
                    {Boolean(terms) && <div className="row">
                        <i className="tiny material-icons">info_outline</i>
                        <a className="terms" href={terms} target="_blank">Pravila in pogoji uporabe</a>
                    </div>}
                </form>
    }

    if (finish) {
        return (<>
                <div className="row">
                    <h2 className="result-title">Hvala za sodelovanje!</h2>
                    {Boolean(endText) && <p>{endText}</p>}
                </div>
                {Boolean(terms) && <div className="row">
                    <i className="tiny material-icons">info_outline</i>
                    <a className="terms" href={terms} target="_blank">Pravila
                        in pogoji uporabe</a>
                </div>}
            </>
        )
    } else {
        return (
            <div className="row">
                <h2 className="result-title">Hvala za sodelovanje</h2>
                {Boolean(endText) && <p>{endText}</p>}
                <p className="form-error">{formError}</p>
                {f}
            </div>
        );
    }
}

export default UserData;
