import axios from 'axios'
import crel from 'crel'
import settings from './settings'
import { hndlr, jsonFromTag } from './util'

const adsWithBottomPadding = [
  // 'pos-sidebar-top', 'pos-sidebar-bottom', 'pos-sidebar-first-block'
]


var viewSize = 'xs'
if (window.innerWidth >= 576) { viewSize = 'sm' }
if (window.innerWidth >= 768) { viewSize = 'md' }
if (window.innerWidth >= 992) { viewSize = 'lg' }
if (window.innerWidth >= 1200) { viewSize = 'xl' }
if (window.innerWidth >= 2000) { viewSize = 'xl' }
const ipromUrl = document.querySelector("#iprom_activator").dataset.iprom


async function iprom_activator() {
  const timeout = 2000
  if (!ipromUrl) return
  const [res, error] = await hndlr(axios.get(ipromUrl))
  if(error) return
  if (Array.isArray(res.data)) {
    fill_iprom_zones(res.data, 700)
    document.readyState !== 'loading'
      ? activate(res.data)
      : window.addEventListener('DOMContentLoaded', ()=>{activate(res.data)})
  } else {
    fill_iprom_zones(res.data.first, 700)
    document.readyState !== 'loading'
      ? activate(res.data.first)
      : window.addEventListener('DOMContentLoaded', ()=>{
        activate(res.data.first)
      })
    fill_iprom_zones(res.data.second, 2000)
    document.readyState !== 'loading'
      ? activate(res.data.second)
      : window.addEventListener('DOMContentLoaded', ()=>{
        activate(res.data.second)
      })
  }
}

function fill_iprom_zones(data, timeout) {
  data.map(i => i.content[viewSize]).flat().forEach(id => {
    window._ipromNS('zone', id, (ad) => {
      console.log(ad)
    })
  })
  window._ipromNS('load',{ timeout: timeout}, function(ads){
    console.log(ads)
  })
}

async function activate(data) {
  ;[...document.querySelectorAll('.iprom_ad')].map(el => {
    const elAdData = data.find(x => x.name == el.id)
    if(!elAdData) return
    elAdData.content[viewSize].forEach(adId => {
      crel(el, crel('div', {class: 'iAdserver', 'data-iadserver-zone': adId}))
      // Add padding to certain elements
      if( adsWithBottomPadding.includes(elAdData.name)) el.style.paddingBottom = "1em"
    })
  })
  if(data.length) insertAdsIntoArticle(data, viewSize)
}

function insertAdsIntoArticle (adIds, viewSize) {
  const positions = jsonFromTag('dataAdvertising')
  if(!positions) return
  const parent = document.querySelector('.article__after.article__content')
  var adPlaces = Array.from(parent.querySelectorAll(':scope > p'))
  if(!adPlaces.length) {
    adPlaces = [...Array.from(parent.querySelectorAll(':scope  *'))].filter((item, index, arr) => {
      if(item.previousElementSibling) {
        return item.nodeName == 'BR' && item.nodeName == arr[index-1].nodeName
      }
    })
  }

  Object.keys(positions).forEach(k => {
    const elAdData = adIds.find(x => x.name == k)
    if(!elAdData) return
    const position = positions[k]
    // debugger
    // New ona hack -- Only show 0 position on SN / ona ***********************
    const isSn = ['-sn', 'slovenskenovice'].map(item => window.location.href.includes(item))
    const isOna = window.location.pathname.startsWith('/ona/')
    if(!(isSn && isOna) && position == 0) return
    // END ona hack ***********************************************************
    elAdData.content[viewSize].forEach(id => {
      const tag = crel('div', {class: 'iAdserver', 'data-iadserver-zone': id})
      tag.style.paddingBottom = "1em"
      try {
        if(adPlaces[position]) adPlaces[position].after(tag)
      } catch (err) {
        console.log(`INFO: No place for ad ${id} in the document`)
      }
    })
  })
}

export {
    iprom_activator
}