import React, { useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client';
import {wait} from './util'

import crel from 'crel'


function checkCookie(typ) {
    return window.Didomi.getUserConsentStatusForPurpose('third')
}


let ConfirmCookie = (props) => {
    const [tiktokHtml, setTiktokHtml] = useState('')
    let playerId = 'nTEqYSoW'
    const baseUrl = window.location.hostname
    if (baseUrl.includes("slovenskenovice.si") ||
        baseUrl.includes("sn")) {
        playerId = '8n88h6J0'
    }
    const handleClickConfirm = async event => {
        window.Didomi.setUserAgreeToAll();
        wait(200)
        window.location.reload()
    }

    const standardConfirmCookiesMessage = (customMessage) => {
        return (
            <div className='notice-container materializecss'>
                <div className="notice-container__cookie">
                    <i className="fas fa-cookie-bite"></i>
                </div>
                <div className='notice-container__notice'>
                    Za prikaz vsebine in boljšo uporabniško izkušnjo prosimo, da omogočite
                    piškotke {customMessage} omrežij.
                </div>
                <div className='notice-container__buttons'>
                    <button className='btn waves-effect waves-light show_more' onClick={() => handleClickConfirm()}
                            style={{fontFamily: 'DeloSerifNormal, "Helvetica Neue", Helvetica, Arial, sans-serif'}}>
                        Omogoči piškotke
                    </button>
                </div>
            </div>
        )
    }

    useEffect(() => {
        const tiktokScript = document.createElement('script')
        const vimeoScript = document.createElement('script')
        const hubspotScripts = {
            s: document.createElement('script'),
            a: document.createElement('script')
        }

        const getTiktok = async () => {
            const response = await fetch('https://www.tiktok.com/oembed?url=' + props.href)
            const responseJson = await response.json()
            setTiktokHtml(responseJson.html)
        }

        if (props.type == 'twitter' && checkCookie("druzabni")) {
            twttr.widgets.load()
        }

        if (props.type == 'tiktok' && checkCookie("druzabni")) {
            getTiktok()
            tiktokScript.src = "https://www.tiktok.com/embed.js"
            tiktokScript.async = true
            document.body.appendChild(tiktokScript)
        }

        if (props.type == 'vimeo' && checkCookie("analiticni-oglasevalski-druzabni")) {
            vimeoScript.src = "https://player.vimeo.com/api/player.js"
            vimeoScript.async = true
            document.body.appendChild(vimeoScript)
        }

        if (props.type == 'jwplayer' && checkCookie("analiticni-oglasevalski-druzabni")) {
            let scrpt = document.getElementById("xxx_jwp_xxx");
            let jwp_scrpt = document.createElement("script");
            jwp_scrpt.type = "text/javascript";
            jwp_scrpt.defer = "true";
            jwp_scrpt.src = `https://services.delo.si/jwp-proxy/?id=${props.id}-${playerId}&m=${window.iprom_m}&sid=${window.SID}&ssid=${window.SSID}`;
            scrpt = document.getElementById("xxx_jwp_xxx");
            scrpt.insertAdjacentElement("afterend", jwp_scrpt);
        }

        if (props.type == 'hubspot' && checkCookie("analiticni")) {
            hubspotScripts.a.textContent = `hbspt.forms.create({region: 'eu1', portalId: '${props.item.dataset.portal_id}', formId: '${props.item.dataset.form_id}'})`
            hubspotScripts.s.src = '//js-eu1.hsforms.net/forms/embed/v2.js'
            hubspotScripts.s.addEventListener("load", () => props.item.appendChild(hubspotScripts.a))
            props.item.appendChild(hubspotScripts.s)
        }

        return () => {
            try {
                document.body.removeChild(tiktokScript)
            } catch (e) {
                console.log('tiktok script was not cleared')
            }
            try {
                document.body.removeChild(vimeoScript)
            } catch (e) {
                console.log('vimeo script was not cleared')
            }
            try {
                document.body.removeChild(hubspotScripts.a)
                document.body.removeChild(hubspotScripts.s)
            } catch (e) {
                console.log('hubspot scripts were not cleared')
            }
        }
    })

    if (props.type == 'youtube' && !checkCookie("druzabni")) {
        return standardConfirmCookiesMessage('družabnih')
    } else if (props.type == 'youtube') {
        return (
            <div className="video-container">
                <iframe className="video-container__video" src={props.href} allowFullScreen></iframe>
            </div>
        )
    }

    if (props.type == 'facebook' && !checkCookie("druzabni")) {
        return standardConfirmCookiesMessage('družabnih')
    } else if (props.type == 'facebook') {
        return (
            <div className="facebook-container">
                <iframe src={`https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(props.href)}`}
                        style={{
                            position: 'relative',
                            top: '0',
                            left: '0',
                            minWidth: '400px',
                            width: '100%',
                            height: '700px',
                            border: 'none',
                            overflow: 'scroll'
                        }}
                        frameBorder="0"
                        scrolling="no"
                        allowFullScreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
            </div>
        )
    }

    if (props.type == 'twitter' && !checkCookie("druzabni")) {
        return standardConfirmCookiesMessage('družabnih')
    } else if (props.type == 'twitter') {
        return (
            <div className="twitter-container">
                <blockquote className="twitter-tweet">
                    <a href={props.href}></a>
                </blockquote>
            </div>
        )
    }

    if (props.type == 'instagram' && !checkCookie("druzabni")) {
        return standardConfirmCookiesMessage('družabnih')
    } else if (props.type == 'instagram') {
        return (
            <div className="instagram-container">
                <div style={{position: 'relative', paddingBottom: '120%', height: '0', overflow: 'hidden'}}>
                    <iframe style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '95%'}}
                            src={`${props.href}embed`}
                            frameBorder="0"
                            scrolling="no"
                            allowtransparency="true"></iframe>
                </div>
            </div>
        )
    }

    if (props.type == 'tiktok' && !checkCookie("druzabni")) {
        return standardConfirmCookiesMessage('družabnih')
    } else if (props.type == 'tiktok') {
        return (
            <div className="tiktok-container">
                <div dangerouslySetInnerHTML={{__html: tiktokHtml}}/>
            </div>
        )
    }

    if (props.type == 'vimeo' && !checkCookie("analiticni-oglasevalski-druzabni")) {
        return standardConfirmCookiesMessage('družabnih, oglaševalskih in analitičnih')
    } else if (props.type == 'vimeo') {
        return (
            <div className="vimeo-container">
                <div style={{padding: '56.25% 0 0 0', position: 'relative', marginBottom: '2em'}}>
                    <iframe src={`${props.href}?h=95bbb2b01b&title=0&byline=0&portrait=0`}
                            style={{position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
                            frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        )
    }

    if (props.type == 'poc-embed' && !checkCookie("analiticni-oglasevalski-druzabni")) {
        return standardConfirmCookiesMessage('družabnih, oglaševalskih in analitičnih')
    } else if (props.type == 'poc-embed') {
        return (
            <div className="poc-embed-container">
                <div dangerouslySetInnerHTML={{__html: decodeURIComponent(props.content)}}/>
            </div>
        )
    }

    if (props.type == 'jwplayer' && !checkCookie("analiticni-oglasevalski-druzabni")) {
        return standardConfirmCookiesMessage('družabnih, oglaševalskih in analitičnih')
    } else if (props.type == 'jwplayer') {
        return (
            <div className="jwplayer-container">
                <script id="xxx_jwp_xxx"></script>
                <div id={`botr_${props.id}_${playerId}_div`}></div>
            </div>
        )
    }

    if (props.type == 'hubspot'  && !checkCookie("analiticni")) {
        return standardConfirmCookiesMessage('analitičnih')
    }

    if (props.type == 'infogram' && !checkCookie("analiticni")) {
        return standardConfirmCookiesMessage('analitičnih')
    } else if (props.type == 'infogram' ) {
        return (<>
            <div className="infogram-embed" data-id={props.id} data-type="interactive" data-title="Infogram"></div>
            {/* This is essentially loaded in _app.tsx with a custom event fired every time */}
            {/* <script>{`!function (e, i, n, s) { { var t = "InfogramEmbeds", d = e.getElementsByTagName("script")[0]; if (window[t] && window[t].initialized) window[t].process && window[t].process(); else if (!e.getElementById(n)) { { var o = e.createElement("script"); o.async = 1, o.id = n, o.src = "https://e.infogram.com/js/dist/embed-loader-min.js", d.parentNode.insertBefore(o, d) } } } }(document, 0, "infogram-async");`}</script> */}
            <div style={{
                padding: '8px 0',
                fontFamily: 'Arial',
                fontSize: '13px',
                lineHeight: '15px',
                textAlign: 'center',
                borderTop: '1px solid #dadada',
                margin: '0 30px'
            }}>
                <a href={`https://infogram.com/${props.id}`}
                    style={{
                        color: '#989898',
                        textDecoration: 'none'
                    }}
                    target="_blank">Infogram
                </a>
            </div>
            {window.dispatchEvent((new CustomEvent('customInfogramLoadEvent')))}
        </>)
    }
}

window.addEventListener('didomiLoaded', async function () {
    let elements = [...document.getElementsByClassName('confirm-cookies')]
    if(elements) elements.forEach(function (item, index) {
        createRoot(item).render(<ConfirmCookie
            key={index}
            item={item}
            type={item.dataset.type}
            href={item.dataset.href}
            content={item.dataset.content}
            portal_id={item.dataset.portal_id}
            id={item.dataset.id}/>)
    })
})