import React, {useEffect, useState} from 'react'
import ProgressLine from "./progress_line";

function Poll({ name, obj, css, section, order, app_id, cat_id }) {
    const [val, setVal] = useState(null);

    const fetchLocalStorage = (step) => {
        if(JSON.parse(localStorage.getItem("poll_app_" + app_id + step))) {
            let b = JSON.parse(localStorage.getItem("poll_app_" + app_id + step));
            setVal(b.answer);
        }
    }

    useEffect(() => {
        fetchLocalStorage(section);
    }, [section]);

    if(name) {
        return (
            <div>
                <div className="poll-item">
                    <ProgressLine
                        label={name}
                        visualParts={[
                          {
                            percentage: obj+'%',
                            color: css.split(';')[0]
                          }
                        ]}
                        val={val}
                        order={order}
                        cat_id={cat_id}
                      />
                </div>
            </div>

            /*
            <div>
                <ProgressLine
                    label={name}
                    visualParts={[
                      {
                        percentage: obj+'%',
                        color: css.split(';')[0]
                      }
                    ]}
                    val={val}
                    order={order}
                    cat_id={cat_id}
                  />
            </div>
             */
        )
    }else
        return null;
}

export default Poll;