import crel from 'crel'

const hndlr = promise => promise
  .then(data => ([data,]))
  .catch(error => ([,error]))

const Q = document.querySelector.bind(document)

const QA = document.querySelectorAll.bind(document)

const toggleClass = function(item, classes) {
  if(!Array.isArray(classes)) classes = [classes]
  classes.forEach(klass => item.classList.contains(klass) ? item.classList.remove(klass) : item.classList.add(klass))
}

function sequentialLoad(urls, callback) {
  const elements = urls.map(url => crel('script', {src: url}))
  for(const [i, v] of elements.entries()) {
    if(i<elements.length-1) elements[i].onload = () => crel(document.body, elements[i+1])
  }
  if(callback) elements[elements.length-1].onload = callback
  crel(document.body, elements[0])
}

function jsonFromTag(id) {
  try {
    return JSON.parse(document.getElementById(id).textContent)
  } catch(err) {
    // console.log('ERROR CAOUGHT:', err)
    return null
  }
}

function getCookie(c_name) {
    var c_value = document.cookie;
    var c_start = c_value.indexOf(" " + c_name + "=");
    if (c_start == -1) {
        c_start = c_value.indexOf(c_name + "=");
    }
    if (c_start == -1) {
        c_value = null;
    }
    else {
        c_start = c_value.indexOf("=", c_start) + 1;
        var c_end = c_value.indexOf(";", c_start);
        if (c_end == -1) {
            c_end = c_value.length;
        }
        c_value = unescape(c_value.substring(c_start, c_end));
    }
    return c_value;
}

function checkCookie(allowed_type) {
    var status = false;
    //Poskusim prebrati cookie allow_cookies
    var cookie_value = getCookie("ALLOW_COOKIES");
    if (cookie_value != null && cookie_value.indexOf(allowed_type) >= 0) {
        //Ustrezno nastavim spremenljivko status, ce je allow_cookies vrednost za izbrano grupo cookijev nastavljena
        status = true;
    }
    return status;
}

function id() {
    const now = Date.now(); // Current timestamp in milliseconds
    const interval = 10 * 60 * 1000; // 10 minutes in milliseconds
    const truncatedTimestamp = Math.floor(now / interval) * interval;
    return new Date(truncatedTimestamp)
    // console.log(truncatedTimestamp); // A unique number every 10 minutes
    // console.log(new Date(truncatedTimestamp)); // The corresponding rounded time
}

function wait(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
}

export {
    hndlr,
    QA,
    Q,
    toggleClass,
    sequentialLoad,
    jsonFromTag,
    getCookie,
    checkCookie,
    id,
    wait
}
