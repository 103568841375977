// import {ga} from "./ga.js";
// import {delostat} from "./delostat.js";
import {clarity} from "./clarity.js";
import { hubspot } from "./hubspot";
// import {infogram} from "./infogram.js";
// import {iprom} from "./iprom.js";
import {matomo} from "./matomo.js";
import {smartoctoAin} from "./smartoctoAin.js";
import {smartoctoTentacles} from "./smartoctoTentacles.js";
// import {twitter} from "./twitter.js";
// import {echobox} from "./echobox.js";
import {facebook} from "./facebook.js";
// import {firebase} from "./firebase.js";
import {liveNetLife} from "./liveNetLife";
import {midas} from "./midas";
// import {outbrain} from "./outbrain";
import {pushNotifications} from "./push_notifications";
import {alliance} from "./alliance";
import {runGoogle} from "../../../../src/es6/google_analytics_didomi";
import {wait} from '../../../../src/es6/util'


var didomiID = 'x7UfP4CE'
if (window.location.host.includes('slovenskenovice.si')) didomiID = 'VhP4cLrG'

window.gdprAppliesGlobally = false;

window.didomiConfig = {};

window.didomiConfig = {
    languages: {
        enabled: ['sl'], // Enable both French and English for all users
    },
    app: {
        apiKey: '4a188492-1524-42c4-be1c-1f74ba134798',
        country: 'sl'
    },
    storageSources: {
      cookies: true,
      localStorage: true
    },
    user: {
        bots: {
            consentRequired: false,
            types: ['crawlers', 'performance']
        }
    }
};
(function () {
    function n(e) {
        if (!window.frames[e]) {
            if (document.body && document.body.firstChild) {
                var t = document.body;
                var r = document.createElement("iframe");
                r.style.display = "none";
                r.name = e;
                r.title = e;
                t.insertBefore(r, t.firstChild)
            } else {
                setTimeout(function () {
                    n(e)
                }, 5)
            }
        }
    }

    function e(r, a, o, c, d) {
        function e(e, t, r, n) {
            if (typeof r !== "function") {
                return
            }
            if (!window[a]) {
                window[a] = []
            }
            var i = false;
            if (d) {
                i = d(e, n, r)
            }
            if (!i) {
                window[a].push({command: e, version: t, callback: r, parameter: n})
            }
        }

        e.stub = true;
        e.stubVersion = 2;

        function t(n) {
            if (!window[r] || window[r].stub !== true) {
                return
            }
            if (!n.data) {
                return
            }
            var i = typeof n.data === "string";
            var e;
            try {
                e = i ? JSON.parse(n.data) : n.data
            } catch (t) {
                return
            }
            if (e[o]) {
                var a = e[o];
                window[r](a.command, a.version, function (e, t) {
                    var r = {};
                    r[c] = {returnValue: e, success: t, callId: a.callId};
                    if (n.source) {
                        n.source.postMessage(i ? JSON.stringify(r) : r, "*")
                    }
                }, a.parameter)
            }
        }

        if (typeof window[r] !== "function") {
            window[r] = e;
            if (window.addEventListener) {
                window.addEventListener("message", t, false)
            } else {
                window.attachEvent("onmessage", t)
            }
        }
    }

    e("__tcfapi", "__tcfapiBuffer", "__tcfapiCall", "__tcfapiReturn");
    n("__tcfapiLocator");
    (function (e, t) {
        var r = document.createElement("link");
        r.rel = "preconnect";
        r.as = "script";
        var n = document.createElement("link");
        n.rel = "dns-prefetch";
        n.as = "script";
        var i = document.createElement("link");
        i.rel = "preload";
        i.as = "script";
        var a = document.createElement("script");
        a.id = "spcloader";
        a.type = "text/javascript";
        a["async"] = true;
        a.charset = "utf-8";
        var o = "https://sdk.privacy-center.org/" + e + "/loader.js?target_type=notice&target=" + t;
        if (window.didomiConfig && window.didomiConfig.user) {
            var c = window.didomiConfig.user;
            var d = c.country;
            var s = c.region;
            if (d) {
                o = o + "&country=" + d;
                if (s) {
                    o = o + "&region=" + s
                }
            }
        }
        r.href = "https://sdk.privacy-center.org/";
        n.href = "https://sdk.privacy-center.org/";
        i.href = o;
        a.src = o;
        var f = document.getElementsByTagName("script")[0];
        f.parentNode.insertBefore(r, f);
        f.parentNode.insertBefore(n, f);
        f.parentNode.insertBefore(i, f);
        f.parentNode.insertBefore(a, f)
    })("4a188492-1524-42c4-be1c-1f74ba134798", didomiID)
})();

window.didomiEventListeners = window.didomiEventListeners || [];
window.didomiEventListeners.push({
  event: 'notice.clickclose',
  listener: function () {
    console.debug("didomi: notice.clickclose");
  }
});
window.didomiEventListeners.push({
  event: 'notice.hidden',
  listener: async function () {
    console.debug("didomi: notice.hidden");
    runGoogle()
    await wait(2000)
    window.location.reload()
  }
});
window.didomiEventListeners.push({
  event: 'preferences.clicksavechoices',
  listener: async function () {
    console.debug("didomi: preferences.clicksavechoices");
    runGoogle()
    await wait(2000)
    window.location.reload()
  }
});
window.didomiEventListeners.push({
  event: 'preferences.clickagreetoall',
  listener: function () {
    console.debug("didomi: preferences.clickagreetoall");
  }
});

window.didomiOnReady = window.didomiOnReady || [];

// console.log(window.didomiOnReady)

window.didomiOnReady.push(function (Didomi) {
    console.debug("didomi: didomiOnReady");
    // ga()
    // iprom()
    hubspot()
    // echobox()
    // infogram()
    matomo()
    smartoctoTentacles()
    smartoctoAin()
    clarity()
    // delostat()
    facebook()
    // twitter()
    // firebase()
    liveNetLife()
    midas()
    //outbrain()
    pushNotifications()
    alliance()
    // Custom event for iprom to know when to fire (iprom_activator.js)
    console.log('didomiLoaded event dispatched')
    window.dispatchEvent(new CustomEvent("didomiLoaded", { detail: { loaded: true } }))
});
