import crel from 'crel'

// window.addEventListener('load', function () {
//     if (checkCookie && !checkCookie("oglasevalski")) return // If cookies are not allowed
//
//     crel(document.head, crel('script', {src: `//cdn.midas-network.com/Widget/IndexAsync/2?portalWidgetId=1390`}))
// })

export const midas = () => {
    console.debug('midas: midas.js');
    const consent = window?.Didomi?.getUserConsentStatusForVendor('c:midas-delo')
    if(!consent) return

    crel(document.head, crel('script', {src: `//cdn.midas-network.com/Widget/IndexAsync/2?portalWidgetId=1390`}))
}