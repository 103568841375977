export const liveNetLife = () => {
    console.debug('live net life: liveNetLife.js');
    const consent = window?.Didomi?.getUserConsentStatusForVendor('c:livenetlife-delo')
    if(!consent) return

    var lnl = document.createElement('script');
    lnl.type = 'text/javascript';
    lnl.async = true;
    lnl.src = (document.location.protocol == 'https:' ? 'https:' : 'http:') + '//bin.livenetlife.com/?CodeId=7db57aad-0cc3-46d4-9e68-c19e88bf8255&LNLReferer=' + encodeURIComponent(window.location.href) + '&cb=' + Math.random().toString().substring(2);
    lnl.id = 'LiveNetLife';
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(lnl, s);
}